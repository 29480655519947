import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AppContainer } from '../AppContainer';
import { successToast } from '../../common/NotificationToast';
import {
  Input,
  InputContainer,
  InputError,
  Label,
  TextArea,
} from '../../common/Inputs';
import { PrimaryButton } from '../../common/Button';
import { SocialIconsComponent } from './SocialIconsComponent';
import { SectionHeader } from './SectionHeader';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Kindly input your name'),
  lastName: Yup.string().required('Kindly input your name'),
  email: Yup.string()
    .required('Kindly input your email')
    .email('Kindly input a valid email'),
  message: Yup.string().required('You need to input your message'),
});
const formOptions = { resolver: yupResolver(validationSchema) };

export const ContactSection = () => {
  const { register, handleSubmit, setError, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const handleContactForm: SubmitHandler<any> = async (value) => {
    // Send http request with form data
    successToast({ message: 'Your message has been received!' });
  };

  return (
    <div>
     
    </div>
  );
};
