import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from "./public/locales/en/en.json";
import de from "./public/locales/de/de.json";
import vi from "./public/locales/vi/vi.json";
import chn from "./public/locales/chn/chn.json";
import tr from "./public/locales/tr/tr.json";
import ru from "./public/locales/ru/ru.json";
import ind from "./public/locales/ind/ind.json";





i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
          translation: en
      },
      de: {
          translation: de
      },
      ru: {

        translation: ru
    },
      vi: {

          translation: vi
      },
      chn: {

        translation: chn
    },
    ind: {

         translation: ind
  },
    tr: {

      translation: tr
  },
    },
    fallbackLng: 'en',
    preload: ['en'],
    keySeparator: false,
    interpolation: { escapeValue: false }
  })

export default i18next
