import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import bigsaleCreate from "../../../assets/images/bigsale-create.json";

export const NoData = () => {
  return (
    <div className="text-center">
       <div>
               
               <Player
 autoplay
 loop
 src={bigsaleCreate}
style={{ height: '300px', width: '300px' }}
>
</Player>
             </div>
      <h3 className="font-bold text-2xl">Create first Launchpad-sale !</h3>
    </div>
  );
};
