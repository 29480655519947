import React, { useState } from 'react';
import { AppContainer } from '../AppContainer';
import Hamburger from 'hamburger-react';
import { NavMenu, ToggleButton } from './styles';
import { SecondaryButton } from '../../common/Button';
import { Link } from 'react-router-dom';
import ScrollToHref from '../../common/ScrollToHref';
import bigsaleLogo from '../../../assets/images/dot_logo.png';
import { Alert } from "../../../components/common/Alerts";
import notif from '../../../assets/images/notification.svg';




const NavLinkItems = ({
  title,
  navLink,
  setNavbar,
}: {
  title: string;
  navLink: string;
  setNavbar: any;
}) => {
  return (
    <ScrollToHref target={navLink}>
      <a
        className="navLink-underline app-font"
        href={`#${navLink}`}
        onClick={setNavbar}
      >
        {title}
      </a>
    </ScrollToHref>
  );
};
// Define an async function to handle viewing a sale
const handleViewSale = async () => {
  // Display an alert with some information
  await Alert({
    title: "Announcement",
    showCloseButton: true,
    message: (
      // Use a div element with some styling and content
      <div className="flex flex-col items-start text-sm border border-gray p-5 ">
        <p>Don’t miss this chance to join us and be part of Bigsale finance  </p>
        <a href="https://medium.com/@bigsale.finance/bigsale-protocols-bsx-token-pre-sale-announced-4a1368bcc203">📖 About Sale</a>
        <a href="https://bigsale.finance/app/launchpad/lists/0x2097CBADf1e7d9F90b4Cf8D7548AF8023764b103">⚡ Visit Sale</a>
      </div>
    ),
  });
};

const NavBarComponent = (): React.ReactElement => {
  const [navbarOpen, setNavbar] = useState(false);
  const [links] = useState([
    { link: '', title: 'Home' },
    { link: 'about', title: 'About' },
    { link: 'features', title: 'Features' },
    { link: 'partners', title: 'Partners' },
  ]);
  const onSetNavbar = (navState: boolean) => {
    setNavbar(navState);
  };

  return (
    <div className="w-full py-4 bg-opacity-50">
      <AppContainer>
        <nav className="w-full flex justify-between items-center">
          <div className="flex items-center w-1/2 space-x-3">
          <a href="/"> <img src={bigsaleLogo} style={{ height: '40px', width: '40px' }} alt="BigSale Logo"/></a> 
          <h3 className="hidden sm:block font-black text-xl">Bigsale Finance</h3>
          </div>
          <NavMenu mobileOpen={navbarOpen}>
            {links.map((link: { title: string; link: string }, index) => (
              <NavLinkItems
                key={index}
                title={link.title}
                navLink={link.link}
                setNavbar={() => onSetNavbar(false)}
              />
            ))}
            <Link onClick={() => setNavbar(false)} to="/app/dashboard">
              <SecondaryButton>Launch App</SecondaryButton>
            </Link>
          </NavMenu>
          <ToggleButton>
            <Hamburger toggled={navbarOpen} toggle={setNavbar} />
          </ToggleButton>
        </nav>
      </AppContainer>
    </div>
  );
};

export default NavBarComponent;
