const routes = [
  {
    path: '/app/dashboard',
    icon: 'home',
    name: 'Feature',
  },
  {
    icon: 'coins',
    name: 'Make token',
    routes: [
      {
        path: '/app/token/create',
        name: 'Mint Token',
      },
    ],
  },
  {
    icon: 'rocket',
    name: 'Launchpad',
    routes: [
      {
        path: '/app/launchpad/create',
        name: 'Create Launchpad',
      },
      {
        path: '/app/fairlaunch/create',
        name: 'Create Fairlaunch',
      },
      {
        path: '/app/launchpad/lists',
        name: 'Launchpad Lists',
      },
    ],
  },
  {
    icon: 'lock',
    name: 'Lock',
    routes: [
      {
        path: '/app/token-lock',
        name: 'Create Lock',
      },
      {
        path: '/app/token-lockers',
        name: 'Locked Tokens',
      },
      // {
      //   path: '/app/liquidity-lockers',
      //   name: 'Liquidity',
      // },
    ],
  },
  {
    icon: 'paper-plane',
    name: 'Multi-Send Token',
    path: '/app/mutisend-token',
  },
  {
    path: '/app/kyc',
    icon: 'id-card',
    name: 'KYC & Audit',
  },
];
export default routes;