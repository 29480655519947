import React, { useState } from 'react';
import { AppContainer } from '../AppContainer';
import fractal from '../../../assets/images/fractal.svg';
import genesis from '../../../assets/images/genesis.svg';
import lupax from '../../../assets/images/lupax.png';
import m6 from '../../../assets/images/m6.svg';
import orion from '../../../assets/images/orion.svg';
import parsiq from '../../../assets/images/parsiq.svg';
import scryptictech from '../../../assets/images/scryptictech.svg';
import plethori from '../../../assets/images/plethori.svg';
import blocksync from '../../../assets/images/blocksync.svg';
import chainlink from '../../../assets/images/chainlink.svg';
import coin98 from '../../../assets/images/coin98.svg';
import x21 from '../../../assets/images/x21.svg';
import styled from 'styled-components';
import { SectionHeader } from './SectionHeader';

const PartnerCompanies = styled.a.attrs(() => ({
  className: `cursor-pointer`,
}))`
  img {
    transition: opacity 0.3s ease;
    opacity: 1;
    max-height: 70px;
    padding: 1rem;

    &:hover {
      opacity: 1;
    }
  }
`;

export const PartnersSection = () => {
  
  return (
    <div>
     
    </div>
  );
};
