import React from 'react';
import {
  HeroSection,
  FeaturesSection,
  ContactSection,
  FooterSection,
  PartnersSection,
  AboutSection,
  RoadMap,
} from '../../components/layout/LandingPage';
import NavBarComponent from '../../components/layout/Navbar';
import { Helmet } from 'react-helmet';
import dotLogo from '../../assets/images/dot_logo.png';


const seoData = {
  title: `BigSale | Defi Launchpad Sale Platform`,
  description: `BigSale ensures that the tokens created are secure and verified. We make sure that the tokens you create are safe and secure for the users. The tokens created on BigSale are also verified and published on explorer websites, allowing you to have a secure IDO launchpad sale.`,
};

const Home = (): React.ReactElement => {
  return (
    <div>
      <Helmet>
        <title>{seoData.title}</title>              

        <meta
          name="description"
          content="Launchpad platform for presales & token creation: Simple & easy to use. Create, lock & airdrop tokens. Host exciting projects before they get listed on exchanges. Get started now!"
        />
        <meta name="og:title" content={seoData.title} />
        <meta name="og:description" content={seoData.description} />
        <meta name="og:type" content="website" />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:site_name" content="Bigsale" />
      </Helmet>
      <NavBarComponent />
      <HeroSection />
      <AboutSection />
      <FeaturesSection />
      <PartnersSection />
      <RoadMap />
      <ContactSection />
      <FooterSection />
    </div>
  );
};

export default Home;
