module.exports = {
  purge: [
    './public/index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './src/*.{js,ts,jsx,tsx}',
  ],
  darkMode: 'class',
  theme: {
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
      '8xl': '6rem',
      '9xl': '7rem',
      '10xl': '10rem',
    },
    extend: {
      colors: {
        custom: {
          light: {
            primaryBackground: '#f4f4f4',
            secondaryBackground: '#ffffff',
            primaryText: '#1f1f1f',
            primaryRed: '#bf9494',
            secondaryRed: '#cf6',
            primaryBlue: '#e5fe00',
            secondaryBlue: '#414141',
            outline: '#00000020',
            primarySiyah: '#222',
            secondaryButton: '#cf6',
            thirtyButton: '#222',
            fourButton: '#cf6',
            iconBackground: '#33363f',




          },
          dark: {
            // primaryBackground: '#050d1f',
            primaryBackground: '#252831',
            // secondaryBackground: '#17133E',
            secondaryBackground: '#33363f;',
            primaryText: '#ffffff',
            primaryRed: '#bf9494',
            secondaryRed: '#ccff661f',
            primaryBlue: '#cf0',
            secondaryBlue: '#3b3e4691',
            outline: '#ffffff20',
            primarySiyah: '#222',
            secondaryButton: '#cf6',
            thirtyButton: '#cf6',
            fourButton: '#cf6',
            iconBackground: '#33363f',


          },
        },
      },
    },
  },
  variants: {},
  plugins: [require('@tailwindcss/forms')],
};
