import React, { useContext, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next'
import { connectMetamask, disconnectMetamask, connectWalletConnect } from '../../../services/blockchainService'
import styled from 'styled-components';
import {
  SidebarContext,
  SideBarContextType,
} from '../../../context/sidebarcontext';
import { MenuItem } from '@szhsin/react-menu';
import { AppModal } from '../../common/AppModal';
import { ModalContent } from '../../common/AppModal/ModalContent';
import { ModalHeader } from '../../common/AppModal/ModalHeader';
import { IconButton, SecondaryButton } from '../../common/Button';
import { AppMenu, AppsMenu } from '../../common/Menu';
import Toggler from '../../common/Toggler';
import BinanceLogo from './images/binance-logo.svg';
import EthLogo from './images/eth-logo.svg';
import PolygonLogo from './images/polygon-logo.png';
import RopstenLogo from './images/ropsten-logo.png';
import MetamaskLogo from './images/metamask.svg';
import MetamaskLogoMobile from './images/metamaskm.svg';
import TrustwalletLogo from './images/twt.svg';
import CoinbaseLogo from './images/coinbase-wallet.svg';
import { shortenAddress } from '../../../utils';
import bigsaleLogo from '../../../assets/images/dot_logo.png';
import AOS from 'aos';
import { getConnectedWallet } from '../../../services/blockchainService';

interface IStyledToolbar {
  className?: string;
  isOpen?: boolean;
}

const ToolBarContainer = styled.div.attrs<IStyledToolbar>(() => ({
  className: `z-10 py-3 px-4 sm:pl-6 sm:pr-10 shadow flex justify-between items-center space-x-4`,
})) <IStyledToolbar>`
  background: ${(props) => props.theme.colors.secondaryBackground};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
`;

const NetworkSelector = styled.button.attrs(() => ({
  className: `w-full flex items-center space-x-3 py-3 pl-3 my-2 rounded-lg font-bold`,
}))`
  transition: all 0.3s ease;
  border: 1px solid ${(props) => props.theme.colors.primaryText}50;

  &:hover {
    box-shadow: 0 10px 17px hsl(0deg 0% 39% / 10%);
    background: ${(props) => props.theme.colors.secondaryRed};
  }
`;

function ToolBar(props:any) {
  const { i18n } = props;
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("i18nextLng"));
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 1000,
      easing: 'ease-out-cubic',
    });
  }, []);

  const onLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  const { toggleSidebar } = useContext<SideBarContextType>(SidebarContext);
  const [modalIsOpen, setModalOpen] = useState(false);
  const onModalClose = () => setModalOpen(false);
  const [walletAddr, setWalletAddr] = useState('');

  const handleConnectMetaMask = async () => {
    const userAddress = await connectMetamask();
    setWalletAddr(userAddress)
  }

  const handleGetConnectedMetaMask = async () => {
    const wallet = await getConnectedWallet()
    if (wallet) {
      setWalletAddr(wallet)
    }
  }

  useEffect(() => {
    handleGetConnectedMetaMask()
  });

  const handleDisconnectMetaMask = () => {
    disconnectMetamask()
    setModalOpen(false)
    setWalletAddr('')

  }
  return (
    <ToolBarContainer>
      <div className="flex items-center w-1/2 space-x-2">
        <div className="justify-end">
          <button onClick={toggleSidebar}>
            <i className="las la-bars text-3xl"></i>
          </button>
        </div>
        <a href="/">
 <img src={bigsaleLogo} style={{ height: '40px', width: '40px' }} alt="bigsale Logo" /></a>

        <h3 className="hidden sm:block font-black text-xl">Bigsale</h3>
      </div>
      <div className="flex space-x-2 w-1/2 items-center justify-end">
        <div>
          <Toggler />
        </div>
        <select defaultValue={i18n.language} onChange={(e) => onLanguageChange(e.target.value)} className="locale-switcher rounded-lg" style={{background: "#33363f00", paddingRight: "0.8rem",fontSize: "1.5rem"}}>        <option value="en">🇬🇧 </option>
        <option value="de">🇩🇪 </option>
        <option value="ind">🇮🇳 </option>
        <option value="ru">🇷🇺 </option>
        <option value="tr">🇹🇷 </option>
        <option value="vi">🇻🇳 </option>
        <option value="chn">🇨🇳 </option>
        
      </select>
        <div className="hidden md:block">
          <img src={BinanceLogo} alt="B" width={25} style={{ display: "inline-block", marginRight: "5px" }} />
          <span>BSC Network</span>
        </div>
        {/*<div className="hidden md:block">
          <SecondaryButton onClick={() => setModalOpen(true)} size="small">
            Networks
          </SecondaryButton>
  </div>*/}
        <IconButton
          onClick={() => setModalOpen(true)}
          className="block md:hidden"
        >
          <i className="lab la-buffer"></i>
        </IconButton>
        {/* <SecondaryButton size="small">Connect</SecondaryButton> */}
        <AppMenu
  align="end"
  transition
  menuButton={<SecondaryButton size="small">{walletAddr
    ? `${shortenAddress(walletAddr)}`
    : `${i18n.t('connect')}`}</SecondaryButton>}
>

          {walletAddr ? <MenuItem>
            <div className="flex space-x-3 items-center py-2" onClick={handleDisconnectMetaMask}>
              <img src={MetamaskLogo} width="30" />
              <span>{i18n.t('disconnect')}</span>

            </div>
          </MenuItem> : <div><MenuItem className="block md:hidden">
            <div className="flex space-x-3 items-center py-2">
              <img src={MetamaskLogoMobile} width="30" /> <a href="https://metamask.app.link/dapp/bigsale.finance/app/dashboard">
     Metamask App
  </a>
            </div>
          </MenuItem>
          <MenuItem>
            <div className="flex space-x-3 items-center py-2" onClick={handleConnectMetaMask}>
              <img src={MetamaskLogo} width="30" />   
              <span>{i18n.t('metamask.con')}</span>
            </div>
          </MenuItem>
          <MenuItem className="block md:hidden">
            <div className="flex space-x-3 items-center py-2">
              <img src={TrustwalletLogo} width="30" /> <a href="https://link.trustwallet.com/open_url?coin_id=20000714&url=https://bigsale.finance/app/dashboard">
    TrustWallet
  </a>
            </div>
          </MenuItem>
            <MenuItem>
              <div className="flex space-x-3 items-center py-2" onClick={connectWalletConnect}>
                <img src={CoinbaseLogo} width="30" />
                <p>Coinbase</p>
              </div>
            </MenuItem></div>}
        </AppMenu>
      </div>
      <div>
        <AppModal modalIsOpen={modalIsOpen} closeModal={onModalClose}>
          <ModalHeader text="Select Network" onModalClose={onModalClose} />
          <ModalContent>
            <NetworkSelector>
              <img src={BinanceLogo} alt="B" width={50} />
              <span>BSC Mainnet</span>
            </NetworkSelector>
            <NetworkSelector>
              <img src={EthLogo} alt="B" width={50} />
              <span>Ethereum Mainnet</span>
            </NetworkSelector>
            <NetworkSelector>
              <img src={PolygonLogo} alt="B" width={50} />
              <span>Matic/Polygon</span>
            </NetworkSelector>
            <div className="my-3 py-4 px-3 font-bold bg-custom-light-primaryBackground dark:bg-custom-dark-primaryBackground">
              <h3>Testnets</h3>
            </div>
            <NetworkSelector>
              <img src={RopstenLogo} alt="B" width={50} />
              <span>Ropsten</span>
            </NetworkSelector>
            <NetworkSelector>
              <img src={BinanceLogo} alt="B" width={50} />
              <span>BSC Testnet</span>
            </NetworkSelector>
          </ModalContent>
        </AppModal>
      </div>
    </ToolBarContainer>
  );
};
export default withTranslation()(ToolBar);
