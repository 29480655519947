import React, { useEffect } from 'react';
// import logo from './logo.svg';
import { withTranslation } from 'react-i18next'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import AOS from 'aos';

function App(props:any) {
  const { i18n } = props;

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("i18nextLng"));
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 1000,
      easing: 'ease-out-cubic',
    });
  }, []);

  const onLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  return (
    <Router>
      {/* <div>
        <NavBar />
      </div> */}
      <div id="modal-root" />
      <Routes>
        <Route path="/app/*" element={<Dashboard />} />
        <Route path="/" element={<Home />} />
        {/* Router does not match */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default withTranslation()(App);
