import React from 'react';
import { PrimaryButton, SecondaryButton } from '../../common/Button';
import { AppContainer } from '../AppContainer';
import { Link } from 'react-router-dom';
import dashboardFrame from '../../../assets/images/bigsale-back.png';
import ScrollToHref from '../../common/ScrollToHref';
import TypeWriter from "typewriter-effect";
import { Player } from '@lottiefiles/react-lottie-player';
import launchImage from "../../../assets/images/bigsale_start.json";

export const HeroSection = (): React.ReactElement => {
  return (
    <div
      className="w-full"
      style={{
        background:
          '#252831 ',
      }}
    >
      <div className="flex flex-col w-full" style={{backgroundImage: `url(${dashboardFrame})`}}>
      <AppContainer>
        <div className="flex flex-col lg:pt-16 pb-10">
        <div className='w-full pt-16'>
              </div>
          <div className="flex flex-col">
            <div className="flex flex-col w-full pt-16 lg:m-0 text-center">
              <div className="max-w-5xl mx-auto">
                <h4
                  data-aos="fade-right"
                  data-aos-delay="500"
                  className="font-bold leading-tight mb-5 app-font"
                >
                  <span className="text-3xl md:text-3xl">
                  Launch Pad Sale  {' '}
                    <span style={{ textShadow: '0 2px 12px #e5fe00' }}>
                    Token Service
                    </span>{' '}
                    Blockchain + AI 
                  </span>
                </h4>
              </div>
              <div className="max-w-3xl mx-auto">
                <h3
                  data-aos="fade-right"
                  data-aos-delay="700"
                  className="text-base md:text-xl font-medium mb-10 text-gray-400"
                >
                  <TypeWriter    
                    options={{
                      loop: true,
                    }}
                    onInit={(typewriter)=> {
              
                    typewriter
                    .changeDelay(30)
                    .typeString("Decentralized launchpad sale protocol")
                    .pauseFor(1000)
                    .deleteAll(10)
                    .typeString("One-Stop to invest in a variety of diversified")
                    .pauseFor(1000)
                    .deleteAll(10)
                    .start();
                      }} />
                </h3>
              </div>
              
              <div className="flex space-x-4 mb-8 justify-center">
                <ScrollToHref target="features">
                  <a href="#features" data-aos="fade-left" data-aos-delay="500">
                    <PrimaryButton>Features</PrimaryButton>
                  </a>
                </ScrollToHref>
                <Link to="/app/dashboard" data-aos="fade" data-aos-delay="800">
                  <SecondaryButton>Launch App</SecondaryButton>
                </Link>
              </div>
            </div>
            <div>
              <div className="w-full pt-16">
              <Player
  autoplay
  loop
  src={launchImage}
 style={{ height: '300px', width: 'auto' }}
>
</Player>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
      </div>
    </div>
  );
};
