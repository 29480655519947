import React, { useState } from 'react';
import ScrollToHref from '../../common/ScrollToHref';
import { AppContainer } from '../AppContainer';
import dotLogo from '../../../assets/images/dot_logo.png';

const FooterLinkItem = ({
  title,
  navLink,
}: {
  title: string;
  navLink: string;
}) => {
  return (
    <ScrollToHref target={navLink}>
      <a className="navLink-underline app-font" href={`#${navLink}`}>
        {title}
      </a>
    </ScrollToHref>
  );
};

export const FooterSection = () => {
  const [links] = useState([
    { link: 'about', title: 'About' },
    { link: 'features', title: 'Features' },
  ]);
  return (
    <div
      className="w-full py-10"
      // style={{ background: '#130C24' }}
    >
      <AppContainer>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 pb-16">
          <div className="space-y-5">
            <h3 className="font-black text-4xl app-font text-center">
              BigSale
            </h3>
            <div className="flex items-center justify-center space-x-4">
              <img src={dotLogo} style={{ height: '75px', width: '75px' }} alt="BigSale Logo" />
              <h5 className="font-bold text-lg">
                Fully <br /> DECENTRALIZED <br /> PROTOCOL
              </h5>
            </div>
            <div className="border-t-4 border-b-4 border-custom-dark-primaryBlue py-6 text-center">
              <a className="text-lg" href="mailto:hi@bigsale.finance">
                hi@bigsale.finance
              </a>
            </div>
          </div>
          <div className="flex flex-col items-start md:items-end">
            <div>
              <div className="border-b-4 border-custom-dark-primaryBlue pb-2 mb-6">
                <h3 className="app-font text-2xl">General</h3>
              </div>
              <ul className="flex flex-col pb-5 items-start">
                {links.map((link: { title: string; link: string }, index) => (
                  <FooterLinkItem
                    key={index}
                    title={link.title}
                    navLink={link.link}
                  />
                ))}
                <a
                  className="navLink-underline app-font"
                  href="https://docs.bigsale.finance/"
                  target="_blank"
                >
                  Documentation
                </a>
                <a
                  className="navLink-underline app-font"
                  href="https://bigsale.finance/app/dashboard"
                  target="_blank"
                >
                  Open App
                </a>
                <a
                  className="navLink-underline app-font"
                  href="https://docs.bigsale.finance/terms/terms-of-service"
                  target="_blank"
                >
                  Terms
                </a>
                <a
                  className="navLink-underline app-font"
                  href="https://docs.bigsale.finance/terms/privacy-policy"
                  target="_blank"
                >
                  Privacy
                </a>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-start lg:items-end">
            <div>
              <div className="border-b-4 border-custom-dark-primaryBlue pb-2 mb-6">
                <h3 className="app-font text-2xl">Others</h3>
              </div>
              <ul className="flex flex-col pb-5 items-start">
                
                <a
                  className="navLink-underline app-font"
                  href="https://twitter.com/bigsalepad"
                  target="_blank"
                >
                  Twitter
                </a>
                <a
                  className="navLink-underline app-font"
                  href="https://t.me/bigsalefinance"
                  target="_blank"
                >
                  Telegram
                </a>
                <a
                  className="navLink-underline app-font"
                  href="https://medium.com/@bigsale.finance"
                  target="_blank"
                >
                  Medium
                </a>
                <a
                  className="navLink-underline app-font"
                  href="https://www.youtube.com/@BigsaleFinance"
                  target="_blank"
                >
                  Youtube
                </a>
              </ul>
            </div>
          </div>
        </div>

        <hr />
        <div className="text-center pt-5 text-gray-400">
          <p>
            &#169; {new Date().getFullYear()} BigSale Finance. All rights reserved
          </p>
        </div>
      </AppContainer>
    </div>
  );
};
